<template>
  <div :id="modalId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
      
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('task.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
            <b-input-group>
              <b-form-input id="name" type="text"
                :data-vv-as="$t('task.field.name')"
                data-vv-name="group.headerName"
                data-vv-delay="500"
                v-model="group.headerName" 
                v-validate="{ required: true }"
                :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'group.headerName')"
                autofocus trim>
              </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.headerName') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      
        <b-col cols="12">
          <b-form-group :label="$t('task.group.field.property')" :class="{ 'mb-0': showPropertyError }">
            <b-input-group>
              <b-form-select v-model="group.property" :options="propertyOpts"
                  :data-vv-as="$t('task.group.field.property')"
                  data-vv-name="group.property"
                  data-vv-delay="500"
                  v-validate="{ required: true }">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>{{ $t('task.group.select_property') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPropertyError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.property') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <label class="mr-1">{{ $t(`task.group.field.tasks`) }}</label>
      <button id="BTN_ADD_TASK" class="btn-action" @click="openTaskNameModal(true)"><font-awesome-icon :icon="['far', 'plus']"/></button>
      <b-popover target="BTN_ADD_TASK" triggers="hover" placement="top">
        {{ $t('task_compact.button.add_task') }}
      </b-popover>
      <button id="BTN_ADD_DYNAMIC_TASK" class="btn-action" @click="addDynamicTasks(true)"><font-awesome-icon :icon="['far', 'arrow-down-to-bracket']"/></button>
      <b-popover target="BTN_ADD_DYNAMIC_TASK" triggers="hover" placement="top">
        {{ $t('task_compact.button.add_dynamic_tasks') }}
      </b-popover>
      <BadgeGroup v-model="group.children">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="columnBadgeRemove(index)" @badgeClick="columnBadgeEdit(index)"
            :text="item.name"
            variant="primary" 
            :pillable="!!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showChildrenError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.children') }}
      </b-form-invalid-feedback>
      
      <template v-slot:modal-footer="{ cancel }">
        <template v-if="canEdit()">
          <b-button size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskGroupTaskEdit v-if="state.taskNameShow" :show.sync="state.taskNameShow" 
      :name="taskName" 
      :color="taskColor"
      :skills="taskSkills"
      :staff="taskStaff"
      :resources="taskResources"
      :rebates="taskRebates"
      :tags="taskTags" 
      :existingNames="existingTaskNames" 
      @ok="taskNameOk" 
      :isNew="taskNameEditIndex == null"
    />

  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { objectClone } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { strRandom } from '@/helpers';

export default {
  name: 'TaskGroupLocalModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    TaskGroupTaskEdit: () => import('@/components/TaskGroup/TaskEdit'),
  },
  props: {
    userId:      { type: String, required: true },
    companyId:   { type: String, required: true },
    uuId:        { type: String, default: null },
    headerName: { type: String, default: null },
    property:    { type: String, default: null },
    propertyOptions: { type: Array, default: null },
    children:    { type: Array, default: () => [] },
    show:        { type: Boolean, required: true },
    dynamicTasks: { type: Array, default: () => [] }
  },
  data() {
    return {
      permissionName: 'TASK',
      modelInfo: null,
      alertMsg: null,
      state: {
        editable: false,
        isSubmitting: false,
        modalShow: false,
        taskNameShow: false,
      },
      group: {
        uuId: null,
        headerName: null,
        property: null,
        children: [
          //Sample:
          //'Layout',
        ]
      },
      taskName: null,
      taskColor: null,
      taskSkills: [],
      taskStaff: [],
      taskResources: [],
      taskRebates: [],
      taskTags: [],
      taskNameEditIndex: null,
      existingTaskNames: []
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.originGroup = null;
    this.propertyOpts = [];
  },
  mounted() {
    this.initModal();
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originGroup = null;
    this.propertyOpts = null;
  },
  computed: {
    modalId() {
      const suffix = this.uuId != null? this.uuId : 'NEW'
      return `TASK_COLUMN_GROUP_LOCAL_${suffix}`;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'group.headerName');
    },
    showPropertyError() {
      return fieldValidateUtil.hasError(this.errors, 'group.property');
    },
    showChildrenError() {
      return fieldValidateUtil.hasError(this.errors, 'group.children');
    },    
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.$t('task.group.title_detail');
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.initModal();
        this.state.modalShow = newValue;
      }
    }
  },
  methods: {
    initModal() {
      this.$validator.resume();
      this.alertMsg = null;
      
      this.group.headerName = this.headerName != null? this.headerName : null;
      this.group.uuId = this.uuId != null? this.uuId : null;
      this.setArray(this.propertyOptions, this, 'propertyOpts');
      this.group.property = this.property != null? this.property : null;
      this.setArray(this.children, this.group, 'children');
      if (this.group.children.length > 0) {
        for (let i = 0, len = this.group.children.length; i < len; i++) {
          this.group.children[i].id = strRandom(5);
        }
      }
      this.originGroup = objectClone(this.group);
    },
    setArray(srcArray, target, property) {
      if (srcArray != null && Array.isArray(srcArray)) {
        target[property] = objectClone(srcArray);
      } else {
        target[property] = [];
      }
    },
    modalOk() {
      this.errors.clear();
      if (this.group.property == null) {
        this.errors.add({
          field: 'group.property',
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.group.field.property')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.handleSave();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    async handleSave() {
      const data = objectClone(this.group);
      
      //Remove unrelated properties
      delete data.propReady;

      let changedChildren = [];
      let hasChanged = false;
      if (this.group.uuId == null) {
        hasChanged = true;
      } else {
        if (!hasChanged && this.originGroup.headerName != data.headerName) {
          hasChanged = true;
        }
        if (!hasChanged && this.originGroup.property != data.property) {
          hasChanged = true;
        }
        if (!hasChanged && this.originGroup.children.length != data.children.length) {
          hasChanged = true;
        }
        if (!hasChanged) {
          for (let i = 0, len = data.children.length; i < len; i++) {
            const childId = data.children[i].id;
            if (childId == null) {
              hasChanged = true;
              continue;
            }
            const originChild = this.originGroup.children.find(j => j.id == childId);
            
            if (originChild.name != data.children[i].name 
                || originChild.color != data.children[i].color 
                || (originChild.skills != null && data.children[i].skills == null)
                || (originChild.skills == null && data.children[i].skills != null)
                || (originChild.staff != null && data.children[i].staff == null)
                || (originChild.staff == null && data.children[i].staff != null)
                || (originChild.resources != null && data.children[i].resources == null)
                || (originChild.resources == null && data.children[i].resources != null)
                || (originChild.rebates != null && data.children[i].rebates == null)
                || (originChild.rebates == null && data.children[i].rebates != null)
                || (originChild.tags != null && data.children[i].tags == null)
                || (originChild.tags == null && data.children[i].tags != null)) {
              hasChanged = true;
              changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
              continue;
            }
            
            //skills
            if (originChild.skills != null) {
              if (originChild.skills.length != data.children[i].skills.length) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
              let count = originChild.skills.length;
              for (const item of originChild.skills) {
                const found = data.children[i].skills.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.level != found.level) {
                  break;
                }
                count--;
              }
              if (count != 0) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
            }

            //staff
            if (originChild.staff != null) {
              if (originChild.staff.length != data.children[i].staff.length) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
              let count = originChild.staff.length;
              for (const item of originChild.staff) {
                const found = data.children[i].staff.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.utilization != found.utilization) {
                  break;
                }
                count--;
              }
              if (count != 0) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
            }

            //resources
            if (originChild.resources != null) {
              if (originChild.resources.length != data.children[i].resources.length) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
              let count = originChild.resources.length;
              for (const item of originChild.resources) {
                const found = data.children[i].resources.find(j => j.uuId == item.uuId);
                if (found == null 
                    || item.name != found.name 
                    || item.quantity != found.quantity 
                    || item.utilization != found.utilization) {
                  break;
                }
                count--;
              }
              if (count != 0) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
            }

            //rebates
            if (originChild.rebates != null) {
              if (originChild.rebates.length != data.children[i].rebates.length) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
              let count = originChild.rebates.length;
              for (const item of originChild.rebates) {
                const found = data.children[i].rebates.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.rebate != found.rebate) {
                  break;
                }
                count--;
              }
              if (count != 0) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
            }

            //tags
            if (originChild.tags != null) {
              if (originChild.tags.length != data.children[i].tags.length) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
              let count = originChild.tags.length;
              for (const item of originChild.tags) {
                const found = data.children[i].tags.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name) {
                  break;
                }
                count--;
              }
              if (count != 0) {
                hasChanged = true;
                changedChildren.push({ oldName: originChild.name, newValue: data.children[i] });
                continue;
              }
            }
          }
        }
      }

      if (hasChanged) {
        changedChildren.forEach(i => {
          delete i.id;
        });
        if (data.children != null && data.children.length > 0) {
          data.children.forEach(i => {
            delete i.id;
          });
        }
        this.$emit('ok', { payload: data, changedChildren: changedChildren });
      } 
      this.$emit('update:show',false);
    },
    
    scrollToTop() {
      document.querySelector(`#${this.modalId}`).scrollIntoView();
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    taskNameOk(payload) {
      const newName = payload.name;
      const newColor = payload.color;
      const newSkills = payload.skills;
      const newStaff = payload.staff;
      const newResources = payload.resources;
      const newRebates = payload.rebates;
      const newTags = payload.tags;
      this.state.taskNameShow = false;
      if (this.taskNameEditIndex != null) {
        const id = this.group.children[this.taskNameEditIndex].id;
        this.group.children.splice(this.taskNameEditIndex, 1, { 
          id: id
          , name: newName
          , color: newColor 
          , skills: newSkills
          , staff: newStaff
          , resources: newResources
          , rebates: newRebates
          , tags: newTags
        });
        this.taskNameEditIndex = null;
      } else {
        this.group.children.push({ 
          name: newName
          , color: newColor 
          , skills: newSkills
          , staff: newStaff
          , resources: newResources
          , rebates: newRebates
          , tags: newTags
        });
      }
      
      this.errors.remove('group.children');
    },
    columnBadgeEdit: function(index) {
      this.taskNameEditIndex = index;
      this.taskName = this.group.children[index].name;
      this.taskColor = this.group.children[index].color;
      if (Array.isArray(this.group.children[index].skills)) {
        this.taskSkills = this.group.children[index].skills;
      }
      if (Array.isArray(this.group.children[index].staff)) {
        this.taskStaff = this.group.children[index].staff;
      }
      if (Array.isArray(this.group.children[index].resources)) {
        this.taskResources = this.group.children[index].resources;
      }
      if (Array.isArray(this.group.children[index].rebates)) {
        this.taskRebates = this.group.children[index].rebates;
      }
      if (Array.isArray(this.group.children[index].tags)) {
        this.taskTags = this.group.children[index].tags;
      }
      this.existingTaskNames.splice(0, this.existingTaskNames.length, ...this.group.children.filter(i => i.name != this.taskName).map(i => i.name));
      this.state.taskNameShow = true;
    },
    columnBadgeRemove: function(index) {
      this.group.children.splice(index,1);
    },
    openTaskNameModal(isNew=false) {
      if (isNew) {
        this.taskNameEditIndex = null;
        this.taskName = null;
        this.taskColor = null;
        this.taskSkills = [];
        this.taskStaff = [];
        this.taskResources = [];
        this.taskRebates = [];
        this.taskTags = [];
      }
      this.existingTaskNames.splice(0, this.existingTaskNames.length, ...this.group.children.map(i => i.name));
      this.state.taskNameShow = true;
    },
    addDynamicTasks() {
      if (this.dynamicTasks != null && this.dynamicTasks.length > 0) {
        const children = this.group.children;
        const dTasks =  Array.from(new Set(this.dynamicTasks)).map(i => ({ name: i }));
        for (const dTask of dTasks) {
          if (children.findIndex(i => i.name === dTask.name) > -1) {
            continue;
          }
          children.push(objectClone(dTask));
        }
      }
    }
  }
}
</script>
